import { render } from "./Tutors.vue?vue&type=template&id=73c3d9f0"
import script from "./Tutors.vue?vue&type=script&lang=ts"
export * from "./Tutors.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "73c3d9f0"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('73c3d9f0', script)) {
    api.reload('73c3d9f0', script)
  }
  
  module.hot.accept("./Tutors.vue?vue&type=template&id=73c3d9f0", () => {
    api.rerender('73c3d9f0', render)
  })

}

script.__file = "src/page/tutors/Tutors.vue"

export default script