<template>
  <div id="tutoringWrap" class="cont_home">
    <section id="tutoringContent">
      <!-- content -->
      <article>
        <LandingSwiper :titleGroup="titleGroup" :swiperCts="swiperCts" :swiperImg="swiperImg"></LandingSwiper>

        <section class="sub_con tt_cmd_con conArea">
          <article class="tt_top_search inner">
            <h2>튜터 소개</h2>
            <nav class="tab">
              <ul>
                <li class="on" data-tutor_type="A">
                  <a href="https://tutoring.co.kr/home/tutors/A">All</a>
                </li>
                <li>
                  <a href="https://tutoring.co.kr/home/tutors/B">Native</a>
                </li>
                <li>
                  <a href="https://tutoring.co.kr/home/tutors/C">Global</a>
                </li>
                <li>
                  <a href="https://tutoring.co.kr/home/tutors/L">Chinese</a>
                </li>
              </ul>
              <div class="tab-guide" id="nativeGuide">
                <b class="point-text">Native 튜터</b>
                는 영미권(주로 미국, 캐나다 등) 튜터로
                <br />
                실제 원어민 표현을 배우고 다양한 취미나 경험 등을 바탕으로 한 자유로운 대화를 나누고 싶은 분들께
                추천드립니다.
              </div>
              <div class="tab-guide" id="globalGuide">
                <b class="point-text">Global 튜터</b>
                는 영어 사용국(필리핀 포함) 튜터로
                <br />
                기본 표현을 익히거나 문장의 패턴을 익히시려는 경우 추천드리며, IELTS, 토플, 토익 등 시험준비를 하고 계신
                분들께도 추천드립니다.
              </div>
            </nav>

            <div class="search form_area">
              <form method="get" action="https://tutoring.co.kr/home/tutors">
                <input type="text" name="q" id="q" value="" placeholder="튜터의 이름, 설명, 관심사 검색" />
              </form>
            </div>
          </article>

          <!-- TODO : 튜터 카드 리스트 서버에서 받아서 프롭 넘기기 -->
          <LandingCard></LandingCard>
          <!-- <article class="tutor_lst_area inner">
						<h3 class="sound_only">튜터 목록</h3>
						<ul class="tutor_lst" style="position: relative; height: 3370px">
							<li class="item" style="position: absolute; left: 0%; top: 0px">
								<a href="https://tutoring.co.kr/home/tutor/7mwx971xwd">
									<div class="t_box">
										<p class="thum">
											<img
												src="https://cdn.tutoring.co.kr/uploads/prof_img/2017/10/09/20171009055922_59da91aa31ae1_M"
											/>
										</p>
										<p class="name">Jemmabelle</p>
										<p class="txt">또박또박한 발음으로 유쾌한 수업! #슈츠</p>
									</div>
									<div class="b_box">
										<p class="lessons">
											<span>21,910</span>
											<i>Lessons</i>
										</p>
										<p class="type">Global</p>
									</div>
								</a>
							</li>
							<li class="item" style="position: absolute; left: 33.2989%; top: 0px">
								<a href="https://tutoring.co.kr/home/tutor/7mwx0514wd">
									<div class="t_box">
										<p class="thum">
											<img
												src="https://cdn.tutoring.co.kr/uploads/prof_img/2017/12/01/20171201174942_5a2117a61a61d_M"
											/>
										</p>
										<p class="name">Laila</p>
										<p class="txt">지금보다 더 나은 영어를 할 수 있게 해줄게요~</p>
									</div>
									<div class="b_box">
										<p class="lessons">
											<span>19,540</span>
											<i>Lessons</i>
										</p>
										<p class="type">Global</p>
									</div>
								</a>
							</li>
							<li class="item" style="position: absolute; left: 66.5978%; top: 0px">
								<a href="https://tutoring.co.kr/home/tutor/v5nqn51q1y">
									<div class="t_box">
										<p class="thum">
											<img
												src="https://cdn.tutoring.co.kr/uploads/prof_img/2021/03/26/20210326122908_605d5504ae0b4_M.jpeg"
											/>
										</p>
										<p class="name">Florence</p>
										<p class="txt">세세한 피드백으로 실력 향상을 도와주는 튜터!</p>
									</div>
									<div class="b_box">
										<p class="lessons">
											<span>18,947</span>
											<i>Lessons</i>
										</p>
										<p class="type">Native</p>
									</div>
								</a>
							</li>
							<li class="item" style="position: absolute; left: 0%; top: 337px">
								<a href="https://tutoring.co.kr/home/tutor/x376dep1qv">
									<div class="t_box">
										<p class="thum">
											<img
												src="https://cdn.tutoring.co.kr/uploads/prof_img/2018/01/14/20180114230845_5a5b646d60eed_M"
											/>
										</p>
										<p class="name">Carmie Anne</p>
										<p class="txt">재미, 영어 자신감 모두 챙겨가는 수업!</p>
									</div>
									<div class="b_box">
										<p class="lessons">
											<span>18,618</span>
											<i>Lessons</i>
										</p>
										<p class="type">Global</p>
									</div>
								</a>
							</li>
							<li class="item" style="position: absolute; left: 33.2989%; top: 337px">
								<a href="https://tutoring.co.kr/home/tutor/o4lqyojwd5">
									<div class="t_box">
										<p class="thum">
											<img
												src="https://cdn.tutoring.co.kr/uploads/prof_img/2018/11/14/20181114232124_5bec2f6402354_M"
											/>
										</p>
										<p class="name">Julienne Marie</p>
										<p class="txt">항상 에너지가 넘치는 친구 같은 튜터!</p>
									</div>
									<div class="b_box">
										<p class="lessons">
											<span>18,552</span>
											<i>Lessons</i>
										</p>
										<p class="type">Native</p>
									</div>
								</a>
							</li>
							<li class="item" style="position: absolute; left: 66.5978%; top: 337px">
								<a href="https://tutoring.co.kr/home/tutor/dmqz8zkmq6">
									<div class="t_box">
										<p class="thum">
											<img
												src="https://cdn.tutoring.co.kr/uploads/prof_img/2019/09/10/20190910121434_5d77151aa8bec_M"
											/>
										</p>
										<p class="name">Cheng</p>
										<p class="txt">영어시험 준비부터 일상 대화까지 재밌게 수업해봐요~</p>
									</div>
									<div class="b_box">
										<p class="lessons">
											<span>17,594</span>
											<i>Lessons</i>
										</p>
										<p class="type">Global</p>
									</div>
								</a>
							</li>
							<li class="item" style="position: absolute; left: 0%; top: 674px">
								<a href="https://tutoring.co.kr/home/tutor/v0qry1z3rn">
									<div class="t_box">
										<p class="thum">
											<img
												src="https://cdn.tutoring.co.kr/uploads/prof_img/2017/07/18/20170718105938_596d6b8ad9a24_M"
											/>
										</p>
										<p class="name">Elia Rose</p>
										<p class="txt">
											오래된 친구처럼 같이 이야기할 수 있는 튜터! #초중급 #수다 #기초부터 #차근차근
										</p>
									</div>
									<div class="b_box">
										<p class="lessons">
											<span>16,498</span>
											<i>Lessons</i>
										</p>
										<p class="type">Global</p>
									</div>
								</a>
							</li>
							<li class="item" style="position: absolute; left: 33.2989%; top: 674px">
								<a href="https://tutoring.co.kr/home/tutor/8d306ryy3r">
									<div class="t_box">
										<p class="thum">
											<img
												src="https://cdn.tutoring.co.kr/uploads/prof_img/2018/11/14/20181114231030_5bec2cd64e3ba_M"
											/>
										</p>
										<p class="name">Cherie</p>
										<p class="txt">
											옆집 언니같은 상냥함+6년 강사경력 #완벽교정수업추구 #박사과정 #교육경력6년
										</p>
									</div>
									<div class="b_box">
										<p class="lessons">
											<span>16,411</span>
											<i>Lessons</i>
										</p>
										<p class="type">Global</p>
									</div>
								</a>
							</li>
							<li class="item" style="position: absolute; left: 66.5978%; top: 674px">
								<a href="https://tutoring.co.kr/home/tutor/v0qr6dpzqr">
									<div class="t_box">
										<p class="thum">
											<img
												src="https://cdn.tutoring.co.kr/uploads/prof_img/2017/04/02/20170402192857_58e0d26921781_M"
											/>
										</p>
										<p class="name">Stefan</p>
										<p class="txt">중요 문법을 콕콕 찝어주는 초급자를 위한 튜터!</p>
									</div>
									<div class="b_box">
										<p class="lessons">
											<span>16,407</span>
											<i>Lessons</i>
										</p>
										<p class="type">Global</p>
									</div>
								</a>
							</li>
							<li class="item" style="position: absolute; left: 0%; top: 1011px">
								<a href="https://tutoring.co.kr/home/tutor/jk32xe2wo9">
									<div class="t_box">
										<p class="thum">
											<img
												src="https://cdn.tutoring.co.kr/uploads/prof_img/2017/01/04/20170104163039_586ca49f4173a_M"
											/>
										</p>
										<p class="name">Maila</p>
										<p class="txt">
											8년간 많은 나라의 학생들을 가르친 노련 튜터! #슈츠 #TESOL #TOEIC #창업
											#비지니스 #FUNFUN
										</p>
									</div>
									<div class="b_box">
										<p class="lessons">
											<span>15,904</span>
											<i>Lessons</i>
										</p>
										<p class="type">Global</p>
									</div>
								</a>
							</li>
							<li class="item" style="position: absolute; left: 33.2989%; top: 1011px">
								<a href="https://tutoring.co.kr/home/tutor/8jq12rv73m">
									<div class="t_box">
										<p class="thum">
											<img
												src="https://cdn.tutoring.co.kr/uploads/prof_img/2017/03/29/20170329103151_58db0e8706655_M"
											/>
										</p>
										<p class="name">May Ann</p>
										<p class="txt">영어학 전공생 + 또박또박한 발음을 가진 튜터!</p>
									</div>
									<div class="b_box">
										<p class="lessons">
											<span>15,853</span>
											<i>Lessons</i>
										</p>
										<p class="type">Global</p>
									</div>
								</a>
							</li>
							<li class="item" style="position: absolute; left: 66.5978%; top: 1011px">
								<a href="https://tutoring.co.kr/home/tutor/d3017xoywr">
									<div class="t_box">
										<p class="thum">
											<img
												src="https://cdn.tutoring.co.kr/uploads/prof_img/2018/11/14/20181114192237_5bebf76d645d8_M"
											/>
										</p>
										<p class="name">MJ</p>
										<p class="txt">어린이와 수업을 짱 잘하는 MJ 튜터 :)</p>
									</div>
									<div class="b_box">
										<p class="lessons">
											<span>15,422</span>
											<i>Lessons</i>
										</p>
										<p class="type">Global</p>
									</div>
								</a>
							</li>
							<li class="item" style="position: absolute; left: 0%; top: 1348px">
								<a href="https://tutoring.co.kr/home/tutor/x6zw51y3j9">
									<div class="t_box">
										<p class="thum">
											<img
												src="https://cdn.tutoring.co.kr/uploads/prof_img/2019/11/15/20191115114159_5dce107713e2d_M"
											/>
										</p>
										<p class="name">Jessica C.</p>
										<p class="txt">
											회화의 기초부터 재밌게 배워봐요~! #초중급 #영어시험 #TOEIC #문법
										</p>
									</div>
									<div class="b_box">
										<p class="lessons">
											<span>14,953</span>
											<i>Lessons</i>
										</p>
										<p class="type">Global</p>
									</div>
								</a>
							</li>
							<li class="item" style="position: absolute; left: 33.2989%; top: 1348px">
								<a href="https://tutoring.co.kr/home/tutor/o7qe95l63d">
									<div class="t_box">
										<p class="thum">
											<img
												src="https://cdn.tutoring.co.kr/uploads/prof_img/2017/12/01/20171201173925_5a21153df158f_M"
											/>
										</p>
										<p class="name">Maricar D.</p>
										<p class="txt">
											바비인형의 광팬인 상냥한 튜터! #초중급 #기초영어 #누구든지 #웃으면서 #수업해용
										</p>
									</div>
									<div class="b_box">
										<p class="lessons">
											<span>14,253</span>
											<i>Lessons</i>
										</p>
										<p class="type">Global</p>
									</div>
								</a>
							</li>
							<li class="item" style="position: absolute; left: 66.5978%; top: 1348px">
								<a href="https://tutoring.co.kr/home/tutor/d8w88ygnwm">
									<div class="t_box">
										<p class="thum">
											<img
												src="https://cdn.tutoring.co.kr/uploads/prof_img/2021/03/26/20210326124315_605d58531600c_M.jpg"
											/>
										</p>
										<p class="name">Revai</p>
										<p class="txt">편~하고 친~절하게 설명해주는 남아공 튜터!</p>
									</div>
									<div class="b_box">
										<p class="lessons">
											<span>13,549</span>
											<i>Lessons</i>
										</p>
										<p class="type">Native</p>
									</div>
								</a>
							</li>
							<li class="item" style="position: absolute; left: 0%; top: 1685px">
								<a href="https://tutoring.co.kr/home/tutor/mwx058v0wd">
									<div class="t_box">
										<p class="thum">
											<img
												src="https://cdn.tutoring.co.kr/uploads/prof_img/2019/04/08/20190408143007_5caadc5fb32b4_M"
											/>
										</p>
										<p class="name">Merie Ann</p>
										<p class="txt">잘 들어주고, 캐주얼수다에 딱인 튜터 메리앤 :)</p>
									</div>
									<div class="b_box">
										<p class="lessons">
											<span>13,360</span>
											<i>Lessons</i>
										</p>
										<p class="type">Global</p>
									</div>
								</a>
							</li>
							<li class="item" style="position: absolute; left: 33.2989%; top: 1685px">
								<a href="https://tutoring.co.kr/home/tutor/2lwm1xew9m">
									<div class="t_box">
										<p class="thum">
											<img
												src="https://cdn.tutoring.co.kr/uploads/prof_img/2018/11/14/20181114232234_5bec2faa71fb3_M"
											/>
										</p>
										<p class="name">Michael</p>
										<p class="txt">영어스피킹 연습 + 배워가는게 많은 수업!</p>
									</div>
									<div class="b_box">
										<p class="lessons">
											<span>13,232</span>
											<i>Lessons</i>
										</p>
										<p class="type">Native</p>
									</div>
								</a>
							</li>
							<li class="item" style="position: absolute; left: 66.5978%; top: 1685px">
								<a href="https://tutoring.co.kr/home/tutor/gq4n18gk37">
									<div class="t_box">
										<p class="thum">
											<img
												src="https://cdn.tutoring.co.kr/uploads/prof_img/2018/06/25/20180625151556_5b30889c5903f_M"
											/>
										</p>
										<p class="name">Sayren</p>
										<p class="txt">8년차 베테랑! 따라하기 쉬운 영어 :)</p>
									</div>
									<div class="b_box">
										<p class="lessons">
											<span>13,149</span>
											<i>Lessons</i>
										</p>
										<p class="type">Global</p>
									</div>
								</a>
							</li>
							<li class="item" style="position: absolute; left: 0%; top: 2022px">
								<a href="https://tutoring.co.kr/home/tutor/mwx078dxwd">
									<div class="t_box">
										<p class="thum">
											<img
												src="https://cdn.tutoring.co.kr/uploads/prof_img/2019/10/22/20191022143916_5dae9604259c1_M"
											/>
										</p>
										<p class="name">Jaco</p>
										<p class="txt">
											여행을 사랑하는, 다양한 주제로 프리토킹을 잘 진행하는 튜터 자코!
										</p>
									</div>
									<div class="b_box">
										<p class="lessons">
											<span>12,988</span>
											<i>Lessons</i>
										</p>
										<p class="type">Native</p>
									</div>
								</a>
							</li>
							<li class="item" style="position: absolute; left: 33.2989%; top: 2022px">
								<a href="https://tutoring.co.kr/home/tutor/2lwm8mxeq9">
									<div class="t_box">
										<p class="thum">
											<img
												src="https://cdn.tutoring.co.kr/uploads/prof_img/2018/11/14/20181114222553_5bec2261adc75_M"
											/>
										</p>
										<p class="name">Ronalyn</p>
										<p class="txt">10년 경험, 친절하고 밝은 분위기에서 수업하는 튜터!</p>
									</div>
									<div class="b_box">
										<p class="lessons">
											<span>12,962</span>
											<i>Lessons</i>
										</p>
										<p class="type">Global</p>
									</div>
								</a>
							</li>
							<li class="item" style="position: absolute; left: 66.5978%; top: 2022px">
								<a href="https://tutoring.co.kr/home/tutor/5nqnrj131y">
									<div class="t_box">
										<p class="thum">
											<img
												src="https://cdn.tutoring.co.kr/uploads/prof_img/2018/03/03/20180303120416_5a9a10b0d3c95_M"
											/>
										</p>
										<p class="name">Ice Kathlyn</p>
										<p class="txt">부담없이! 편안~하게 수업을! #초중급 #밝게 #문법 #어린이</p>
									</div>
									<div class="b_box">
										<p class="lessons">
											<span>12,256</span>
											<i>Lessons</i>
										</p>
										<p class="type">Global</p>
									</div>
								</a>
							</li>
							<li class="item" style="position: absolute; left: 0%; top: 2359px">
								<a href="https://tutoring.co.kr/home/tutor/mqz9z4v536">
									<div class="t_box">
										<p class="thum">
											<img
												src="https://cdn.tutoring.co.kr/uploads/prof_img/2021/03/31/20210331140816_606403c02cb58_M.jpg"
											/>
										</p>
										<p class="name">Joan</p>
										<p class="txt">친구같은 + 밝은 + 편한 튜터 :)</p>
									</div>
									<div class="b_box">
										<p class="lessons">
											<span>12,226</span>
											<i>Lessons</i>
										</p>
										<p class="type">Global</p>
									</div>
								</a>
							</li>
							<li class="item" style="position: absolute; left: 33.2989%; top: 2359px">
								<a href="https://tutoring.co.kr/home/tutor/8jq18yy73m">
									<div class="t_box">
										<p class="thum">
											<img
												src="https://cdn.tutoring.co.kr/uploads/prof_img/2018/11/14/20181114195711_5bebff874da95_M"
											/>
										</p>
										<p class="name">Vivien</p>
										<p class="txt">13년차 영어티칭 전문 튜터!</p>
									</div>
									<div class="b_box">
										<p class="lessons">
											<span>12,161</span>
											<i>Lessons</i>
										</p>
										<p class="type">Global</p>
									</div>
								</a>
							</li>
							<li class="item" style="position: absolute; left: 66.5978%; top: 2359px">
								<a href="https://tutoring.co.kr/home/tutor/kwvzmr9pqp">
									<div class="t_box">
										<p class="thum">
											<img
												src="https://cdn.tutoring.co.kr/uploads/prof_img/2019/02/10/20190210004106_5c5ef4922ac5e_M"
											/>
										</p>
										<p class="name">Mary Ann M.</p>
										<p class="txt">
											차분한 목소리로 수업하지만 수업을 재미있게 진행하는 튜터 매리앤!
										</p>
									</div>
									<div class="b_box">
										<p class="lessons">
											<span>12,061</span>
											<i>Lessons</i>
										</p>
										<p class="type">Global</p>
									</div>
								</a>
							</li>
							<li class="item" style="position: absolute; left: 0%; top: 2696px">
								<a href="https://tutoring.co.kr/home/tutor/mqzrl96y36">
									<div class="t_box">
										<p class="thum">
											<img
												src="https://cdn.tutoring.co.kr/uploads/prof_img/2018/06/25/20180625141618_5b307aa20c178_M"
											/>
										</p>
										<p class="name">Adrian J.</p>
										<p class="txt">피드백굿 + 세심한 튜터:)</p>
									</div>
									<div class="b_box">
										<p class="lessons">
											<span>11,971</span>
											<i>Lessons</i>
										</p>
										<p class="type">Global</p>
									</div>
								</a>
							</li>
							<li class="item" style="position: absolute; left: 33.2989%; top: 2696px">
								<a href="https://tutoring.co.kr/home/tutor/7qevk1z6wd">
									<div class="t_box">
										<p class="thum">
											<img
												src="https://cdn.tutoring.co.kr/uploads/prof_img/2018/11/16/20181116171234_5bee7bf23c248_M"
											/>
										</p>
										<p class="name">Rose A.</p>
										<p class="txt">매니저 추천 튜터! 활발한성격+좋은발음+능숙한티칭!</p>
									</div>
									<div class="b_box">
										<p class="lessons">
											<span>11,894</span>
											<i>Lessons</i>
										</p>
										<p class="type">Global</p>
									</div>
								</a>
							</li>
							<li class="item" style="position: absolute; left: 66.5978%; top: 2696px">
								<a href="https://tutoring.co.kr/home/tutor/d8w8g1e3m1">
									<div class="t_box">
										<p class="thum">
											<img
												src="https://cdn.tutoring.co.kr/uploads/prof_img/2017/07/18/20170718110031_596d6bbfdde24_M"
											/>
										</p>
										<p class="name">Joy</p>
										<p class="txt">
											한국학생들의 마음을 잘 아는 튜터! #초중급 #재밌게 #천천히 #영어로
										</p>
									</div>
									<div class="b_box">
										<p class="lessons">
											<span>11,714</span>
											<i>Lessons</i>
										</p>
										<p class="type">Global</p>
									</div>
								</a>
							</li>
							<li class="item" style="position: absolute; left: 0%; top: 3033px">
								<a href="https://tutoring.co.kr/home/tutor/x376086pqv">
									<div class="t_box">
										<p class="thum">
											<img
												src="https://cdn.tutoring.co.kr/uploads/prof_img/2020/11/26/20201126153019_5fbf4b7b4f475_M.jpeg"
											/>
										</p>
										<p class="name">Tori</p>
										<p class="txt">시작이 반이다, 6년차 경력의 튜터 Victoria:) #슈츠</p>
									</div>
									<div class="b_box">
										<p class="lessons">
											<span>11,662</span>
											<i>Lessons</i>
										</p>
										<p class="type">Global</p>
									</div>
								</a>
							</li>
							<li class="item" style="position: absolute; left: 33.2989%; top: 3033px">
								<a href="https://tutoring.co.kr/home/tutor/mqzlg96436">
									<div class="t_box">
										<p class="thum">
											<img
												src="https://cdn.tutoring.co.kr/uploads/prof_img/2018/10/11/20181011081825_5bbe88c13c8e9_M"
											/>
										</p>
										<p class="name">Rachel J.</p>
										<p class="txt">부산에서 2년반 초등학교 쌤 경험한 레이첼 튜터!</p>
									</div>
									<div class="b_box">
										<p class="lessons">
											<span>11,056</span>
											<i>Lessons</i>
										</p>
										<p class="type">Native</p>
									</div>
								</a>
							</li>
							<li class="item" style="position: absolute; left: 66.5978%; top: 3033px">
								<a href="https://tutoring.co.kr/home/tutor/1x3756z9wv">
									<div class="t_box">
										<p class="thum">
											<img
												src="https://cdn.tutoring.co.kr/uploads/prof_img/2021/03/26/20210326123135_605d5597743d7_M.jpg"
											/>
										</p>
										<p class="name">Ismail</p>
										<p class="txt">현직 신문기자가 가르쳐주는 고급 영어!</p>
									</div>
									<div class="b_box">
										<p class="lessons">
											<span>10,943</span>
											<i>Lessons</i>
										</p>
										<p class="type">Native</p>
									</div>
								</a>
							</li>
						</ul>

						<div class="page_type2">
							<ul class="pagination">
								<li class="disabled"><span>«</span></li>

								<li class="active"><span>1</span></li>
								<li><a href="https://tutoring.co.kr/home/tutors?page=2">2</a></li>
								<li><a href="https://tutoring.co.kr/home/tutors?page=3">3</a></li>
								<li><a href="https://tutoring.co.kr/home/tutors?page=4">4</a></li>
								<li><a href="https://tutoring.co.kr/home/tutors?page=5">5</a></li>
								<li><a href="https://tutoring.co.kr/home/tutors?page=6">6</a></li>
								<li><a href="https://tutoring.co.kr/home/tutors?page=7">7</a></li>
								<li><a href="https://tutoring.co.kr/home/tutors?page=8">8</a></li>

								<li class="disabled"><span>...</span></li>

								<li><a href="https://tutoring.co.kr/home/tutors?page=22">22</a></li>
								<li><a href="https://tutoring.co.kr/home/tutors?page=23">23</a></li>

								<li><a href="https://tutoring.co.kr/home/tutors?page=2" rel="next">»</a></li>
							</ul>
						</div>
					</article> -->
        </section>
      </article>
      <!-- // content -->
    </section>
  </div>
</template>
<script lang="ts">
import { LandingSwiper, LandingCard } from '@/components';

export default {
  name: 'Tutors',
  components: {
    LandingSwiper,
    LandingCard,
  },
  data() {
    return {
      titleGroup: ['바로 지금', '나만의 튜터 접속 중'],
      swiperImg: [
        '//cdn.tutoring.co.kr/uploads/home/img/topic/tutor_card_01.png',
        '//cdn.tutoring.co.kr/uploads/home/img/topic/tutor_card_02.png',
        '//cdn.tutoring.co.kr/uploads/home/img/topic/tutor_card_03.png',
        '//cdn.tutoring.co.kr/uploads/home/img/topic/tutor_card_04.png',
      ],
      swiperCts: [
        '지금, 이순간도 튜터 대기 중',
        '가자? 음악감독? 튜터!',
        '완전한 개인지도, 1:1 피드백',
        '내게 꼭 맞는 엄선된 튜터',
      ],
    };
  },
};
</script>

<style></style>
