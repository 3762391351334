
import { LandingSwiper, LandingCard } from '@/components';

export default {
  name: 'Tutors',
  components: {
    LandingSwiper,
    LandingCard,
  },
  data() {
    return {
      titleGroup: ['바로 지금', '나만의 튜터 접속 중'],
      swiperImg: [
        '//cdn.tutoring.co.kr/uploads/home/img/topic/tutor_card_01.png',
        '//cdn.tutoring.co.kr/uploads/home/img/topic/tutor_card_02.png',
        '//cdn.tutoring.co.kr/uploads/home/img/topic/tutor_card_03.png',
        '//cdn.tutoring.co.kr/uploads/home/img/topic/tutor_card_04.png',
      ],
      swiperCts: [
        '지금, 이순간도 튜터 대기 중',
        '가자? 음악감독? 튜터!',
        '완전한 개인지도, 1:1 피드백',
        '내게 꼭 맞는 엄선된 튜터',
      ],
    };
  },
};
